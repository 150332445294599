import store from 'client/store';
import auth from 'client/auth';

import find from 'lodash/find';

export default [
  {
    path: '/supervisor/:eventId',
    component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/wrapper.vue'),
    beforeEnter: function (to, from, next) {
        auth.check().then(() => {
            store.dispatch("events.getById", {
              eventId: to.params.eventId,
            }).then(() => {
              const query = { eventId: to.params.eventId };
              Promise.all([
                store.dispatch("eventDisciplines.get", query),
                store.dispatch("members.get", query),
                store.dispatch("groups.get", query),
                store.dispatch("clubs.get", query),
                store.dispatch("teams.get", query),
                store.dispatch("sessions.getByEvent", query),
              ]).then(() => {
                next();
              });
            });
        }).catch(function () {
            next({
                path: '/login'
            });
        })
    },
    children: [{
      path: '/',
      name: 'supervisor.sessions',
      component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/sessions.vue'),
    }, {
      path: 'session/:sessionId',
      component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/session/wrapper.vue'),
      beforeEnter: function (to, from, next) {
        const session = find(store.state.sessions.items, item => { return item.id === to.params.sessionId });
        const query =  { eventDisciplineId: session.eventDisciplineId };
        const sQuery = { sessionId: to.params.sessionId };

        Promise.all([
          store.dispatch("exercises.get", query),
          store.dispatch("eventDiscipline.get", query),
          store.dispatch("categories.get", query),
          store.dispatch("participations.get", query),
          store.dispatch("blocks.get", query),
          store.dispatch("blockParticipations.get", query),
          store.dispatch("rankings.get", query),
          store.dispatch("panels.getBySession", sQuery).then(() => { next() }),
        ]).then(next);
      },
      children: [{
        path: '/',
        name: 'supervisor.panels',
        component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/session/panels.vue'),
      }, {
        path: 'panel/:panelId',
        name: 'supervisor.panel',
        component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/session/panel.vue'),
        meta: { hideHeader: true },
      }, {
        path: 'results',
        component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/results/wrapper.vue'),
        meta: { hideHeader: true },
        children: [{
          path: '/',
          name: 'supervisor.results',
          component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/results/rankings.vue'),
        },{
          path: ':categoryId',
          component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/results/category.vue'),
          children: [{
            path: '/',
            name: 'supervisor.category',
            component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/results/categoryDummy.vue'),
          },{
            path: 'ranking/:rankingId',
            name: 'supervisor.ranking',
            component: () => import(/* webpackChunkName: "supervisor" */ 'client/supervisor/results/ranking.vue'),
          }],
        }]
      }]
    }]
  }
];
