export default {
  path: 'participants',
  component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/wrapper.vue'),
  children: [{
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/singleWrapper.vue'),
    children: [{
      path: '/',
      name: 'admin.event.discipline.participants',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/list.vue'),
    },{
      path: 'add',
      name: 'admin.event.discipline.participants.add',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/add.vue'),
    },{
      path: 'addGroup',
      name: 'admin.event.discipline.participants.addGroup',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/addGroup.vue'),
    }, {
      path: ':participationId',
      name: 'admin.event.discipline.participants.view',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/view.vue'),
    }]
  }, {
    path: 'teams',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/teamWrapper.vue'),
    children: [{
      path: '/',
      name: 'admin.event.discipline.participantsTeam',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/listTeam.vue'),
    }, {
      path: 'addTeam',
      name: 'admin.event.discipline.participants.addTeam',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/addTeam.vue'),
    }, {
      path: ':participationId',
      name: 'admin.event.discipline.participants.viewTeam',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/participants/viewTeam.vue'),
    }]
  }]
};
