import store from 'client/store';

export default {
  path: 'dashboard',
  name: 'admin.event.discipline.dashboard',
  component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/dashboard/dashboard.vue'),
  beforeEnter: function (to, from, next) {
    store.dispatch("displays.load", {
      eventId: to.params.eventId
    }).then( () => { next(); });
  },
  children: [{
    path: 'session/:sessionId',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/dashboard/sessionWrapper.vue'),
    beforeEnter: function (to, from, next) {
      const filter = { sessionId: to.params.sessionId };
      store.dispatch("panels.getBySession", filter).then(() => { next() });
    },
    children: [{
      path: '/',
      name: 'admin.event.discipline.dashboard.session',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/dashboard/sessionDashboard.vue'),
    },{
      path: 'block/:panelId/:rotation',
      name: 'admin.event.discipline.dashboard.block',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/dashboard/blockDashboard.vue'),
      props: true,
    },{
      path: 'exercise/:panelId/:rotation/:exerciseId/:pass',
      name: 'admin.event.discipline.dashboard.exercise',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/dashboard/exercise.vue'),
      beforeEnter: function(to, from, next) {
        store.dispatch('exercise.load', {
          panelId: to.params.panelId,
          exerciseId: to.params.exerciseId,
          pass: parseInt(to.params.pass)
        }).then(() => { next() });
      }
    },{
      path: 'selections/:categoryId/:type/:exerciseTypeId',
      name: 'admin.event.discipline.dashboard.selections',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/dashboard/selections.vue'),
      props: true,
    }]
  }]
};
