
import auth from "client/auth";

import store from 'client/store';

const find = require('lodash/find')

export default [
  {
    path: '/cis/:eventId',
    component: () => import(/* webpackChunkName: "cis" */ 'client/cis/components/wrapper.vue'),
    beforeEnter: function (to, from, next) {
        auth.check().then(function() {
            store.dispatch("events.getById", {
                eventId: to.params.eventId
            }).then(() => {
              const query = { eventId: to.params.eventId };
              Promise.all([
                store.dispatch("eventDisciplines.get", query),
                store.dispatch("sessions.getByEvent", query),
                store.dispatch("panels.getByEvent", query),
                store.dispatch("members.get", query),
                store.dispatch("groups.get", query),
                store.dispatch("teams.get", query),
                store.dispatch("clubs.get", query)
              ]).then(next);
            });
        }).catch(function () {
            next({
                path: '/login'
            });
        })
    },
    children: [{
      path: '/',
      name: 'cis.sessions',
      component:  () => import(/* webpackChunkName: "cis" */ 'client/cis/components/sessions.vue'),
    }, {
      path: 'session/:sessionId',
      component:  () => import(/* webpackChunkName: "cis" */ 'client/cis/components/session.vue'),
      meta: { hideHeader: true },
      beforeEnter: function (to, from, next) {
        const session = find(store.state.sessions.items, item => { return item.id === to.params.sessionId });
        const sFilter = { sessionId: to.params.sessionId }
        const dFilter =  { eventDisciplineId: session.eventDisciplineId };

        Promise.all([
          store.dispatch('exercises.get', dFilter),
          store.dispatch('eventDiscipline.get', dFilter),
          store.dispatch('categories.get', dFilter),
          store.dispatch('participations.get', dFilter),
          store.dispatch('blocks.getSession', sFilter),
          store.dispatch('blockParticipations.getSession', sFilter),
          store.dispatch('rankings.get', dFilter),
        ]).then(next);
      },
      children: [{
        path: '/',
        name: 'cis.session.panels',
        component: () => import(/* webpackChunkName: "cis" */ 'client/cis/components/panels.vue'),
      }, {
        path: 'results',
        component: () => import(/* webpackChunkName: "cis" */ 'client/cis/components/results.vue'),
        children: [{
          path: '/',
          name: 'cis.session.results',
          component: () => import(/* webpackChunkName: "cis" */ 'client/cis/components/idle.vue'),
        }, {
          path: 'category/:categoryId',
          component: () => import(/* webpackChunkName: "cis" */ 'client/cis/components/sessionCategory.vue'),
          children: [{
            path: '/',
            name: 'cis.category',
            component: () => import(/* webpackChunkName: "cis" */ 'client/cis/components/idle.vue'),
          }, {
            path: 'ranking/:rankingId',
            name: 'cis.ranking',
            component: () => import(/* webpackChunkName: "cis" */ 'client/cis/components/sessionRanking.vue'),
          }]
        }]
      }]
    }]
  }
];
