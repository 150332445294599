import store from "client/store";

export default {
  path: 'import',
  component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/wrapper.vue'),
  beforeEnter: function (to, from, next) {
    store.dispatch("imports.get", {
      eventDisciplineId: to.params.disciplineId,
    }).then(() => {
      next();
    });
  },
  children: [{
    path: '/',
    name: 'admin.event.discipline.import',
    component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/list.vue'),
  }, {
    path: 'add',
    component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/add-wrapper.vue'),
    children: [{
      path: '/',
      name: 'admin.event.discipline.import.add',
      component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/add.vue'),
    }, {
      path: 'csv',
      name: 'admin.event.discipline.import.add.csv',
      component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/add-csv.vue'),
    }, {
      path: 'sce-event',
      name: 'admin.event.discipline.import.add.sce-event',
      component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/add-sce-event.vue'),
    }, {
      path: 'federation',
      name: 'admin.event.discipline.import.add.federation',
      component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/add-federation.vue'),
    }]
  }, {
    path: ':importId',
    beforeEnter: function (to, from, next) {
      store.dispatch("import.load", to.params.importId).then(() => {
        next();
      });
    },
    component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/view.vue'),
    children: [{
      path: '/',
      name: 'admin.event.discipline.import.view',
      component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/viewSummary.vue'),
    }, {
      path: ':importType',
      name: 'admin.event.discipline.import.view.detail',
      component: () => import(/* webpackChunkName: "import" */ 'client/admin/event/import/viewDetail.vue'),
    }]
  }],
};
