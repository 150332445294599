<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <vue-progress-bar></vue-progress-bar>
    <router-view></router-view>
    <modalSimple />
  </div>
</template>

<script>
  export default {
    name: "import-index",
  }
</script>

<style>
</style>
