<template>
  <login @login="login($event)" type="import">
    <div slot="feedback">
      <div>
        {{$t('importer.login.message')}}
      </div>
      <div v-if="feedback" class="c-form--error">
        {{feedback}}
      </div>
    </div>
  </login>
</template>
<script>

  export default {
    name: "importLogin",
    components: {
      login: require('client/components/login.vue').default,
    },
    data: function () {
      return {
        feedback: '',
      };
    },
    methods: {
      login: function (event) {
        this.$validator.validateAll();
        if (!this.errors.any()) {
          this.$store.dispatch("importer.login", event.credentials).then(() => {
            this.feedback = null;
            this.$navigation.goto({
              name: "importer.syncUser",
            });
          }, () => {
            this.feedback = this.$t('authentication.error');
          });
        }
      },
    },
  };
</script>

<style scoped>
</style>
