import store from 'client/store';

import find from 'lodash/find';

export default {
  path: 'user/:userId',
  component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/user.vue'),
  children: [{
    path: '/',
    name: 'admin.user.profile',
    component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/profile.vue'),
  },{
    path: 'displayTemplates',
    component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/displayTemplates/wrapper.vue'),
    beforeEnter: function(to, from, next) {
      store.dispatch("displayTemplates.load", {
        userId: store.state.user.instance.id,
      }).then(() => {
        next();
      });
    },
    children: [{
      path: '/',
      name: 'admin.user.displayTemplates',
      component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/displayTemplates/list.vue'),
    }, {
      path: ':templateId',
      name: 'admin.user.displayTemplate.view',
      component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/displayTemplates/viewSet.vue'),
    }, {
      path: 'add',
      name: 'admin.user.displayTemplate.add',
      component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/displayTemplates/addSet.vue'),
    }]
  },{
    path: 'planningDefaults',
    component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/wrapper.vue'),
    beforeEnter: function(to, from, next) {
      store.dispatch("planningDefaults.load", {
        userId: store.state.user.instance.id,
      }).then(() => {
        next();
      });
    },
    children: [{
      path: '/',
      name: 'admin.user.planningDefaults',
      component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/list.vue'),
    }, {
      path: ':planningDefaultId',
      component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/planningWrapper.vue'),
      children: [{
        path: '/',
        name: 'admin.user.planningDefault.view',
        component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/viewConfig.vue'),
      }, {
        path: 'templates',
        component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/templateWrapper.vue'),
        beforeEnter: function(to, from, next) {
          const planningDefault = find(store.state.planningDefaults.items, i => i.id === to.params.planningDefaultId);
          store.dispatch("importTemplates.load", {
            userId: store.state.user.instance.id,
            disciplineId: planningDefault.disciplineId,
          }).then(() => {
            next();
          });
          // next();
        },
        children: [{
          path: '/',
          name: 'admin.user.planning.templates',
          component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/templateList.vue'),
        }, {
          path: ':templateId',
          name: 'admin.user.planning.template.edit',
          component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/templateView.vue'),
        }, {
          path: 'add',
          name: 'admin.user.planning.template.add',
          component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/templateAdd.vue'),
        }]
      }]
    }, {
      path: 'add',
      name: 'admin.user.planningDefault.add',
      component: () => import(/* webpackChunkName: "adminUser" */ 'client/admin/user/planning/addConfig.vue'),
    }]
  }],
};
