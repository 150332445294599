import store from 'client/store';
import userLib from 'client/lib/user';

export default {
  path: 'sync',
  component: () => import(/* webpackChunkName: "admin" */ 'client/admin/sync/wrapper.vue'),
  beforeEnter: function (to, from, next) {
    if (store.state.environment.local) {
      console.log('fetch list of remote events');

      if (userLib.checkRole('admin')) {
        store.dispatch("importer.events.getAdmin").then(next);
      } else {
        store.dispatch("importer.events.get").then(next);
      }
    } else {
      next({
        path: '/login'
      });
    }
  },
  children: [{
    path: '/',
    name: 'admin.sync.list',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/sync/list.vue'),
  }, {
    path: 'event/:eventId',
    name: 'admin.sync.event',
    beforeEnter: function(to, from, next) {
      store.dispatch('importer.event.get', to.params.eventId).then(next)
    },
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/sync/event.vue'),
  }]
};
