
export default {
  path: 'categories',
  component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/categories/wrapper.vue'),
  children: [{
    path: '/',
    name: 'admin.event.discipline.categories',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/categories/list.vue'),
  }, {
    path: 'add',
    name: 'admin.event.discipline.categories.add',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/categories/add.vue'),
  }, {
    path: ':categoryId',
    name: 'admin.event.discipline.categories.view',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/categories/view.vue'),
  }]
};
