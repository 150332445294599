import auth from '@/auth';
import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let routes = [
  {
    path: '/login',
    name: 'login',
    component: require('@/components/loginPage.vue').default,
    beforeEnter: function (to, from, next) {
      if (store.state.environment.local && ! store.state.environment.localUser) {
        console.log('no local user');
        return next({path: 'importer/login'});
      }

      if (to.query.userId && to.query.token && to.query.destination) {
        auth.create({
          userId: to.query.userId,
          token: to.query.token
        }).then(function () {
          next({path: to.query.destination})
        }).catch(err => {
          console.log(err);
        });
      } else {
        next();
      }
    }
  },
  {
    path: '/admin-login',
    name: 'admin-login',
    component: require('@/components/adminLoginPage.vue').default,
    beforeEnter: function (to, from, next) {
      if (to.query.userId && to.query.token && to.query.destination) {
        auth.create({
          userId: to.query.userId,
          token: to.query.token
        }).then(function () {
          next({path: to.query.destination})
        }).catch(err => {
          console.log(err);
        });
      } else {
        next();
      }
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: function (to, from, next) {
      auth.logout();
      next({
        name: 'login'
      })
    }
  }
];

// admin app routes
import adminRoutes from '@/admin/routes';
// import displayRoutes from '@/display/routes';
import judgeRoutes from '@/judge/routes';
import supervisorRoutes from '@/supervisor/routes';
import importerRoutes from '@/importer/routes';
import cisRoutes from '@/cis/routes';

routes = routes.concat(adminRoutes);
// routes = routes.concat(displayRoutes);
routes = routes.concat(judgeRoutes);
routes = routes.concat(supervisorRoutes);
routes = routes.concat(importerRoutes);
routes = routes.concat(cisRoutes);
routes = routes.concat([{path: '*', redirect: '/admin'}]);

const router = new VueRouter({
  routes: routes,
  linkActiveClass: 'is-active'
});

export default router;
