import store from 'client/store';

export default [{
  path: '/importer',
  name: 'importer',
  beforeEnter: function (to, from, next) {
    if (store.state.environment.local) {
      next();
    } else {
      next({
        path: '/login'
      });
    }
  },
  component: require('client/importer/components/index.vue').default,
  children: [{
    name: 'importer.login',
    path: 'login',
    component: require('client/importer/components/login.vue').default,
  }, {
    name: 'importer.syncUser',
    path: '/syncUser',
    component: require('client/importer/components/syncUser.vue').default,
    beforeEnter: function(to, from, next) {
      store.dispatch('importer.user.set').then(() => next(), () => next({name: 'importer.login'}));
    }
  }],
}];
