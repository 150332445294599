<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <vue-progress-bar></vue-progress-bar>
    <login @login="login($event)" type="admin">
      <div slot="feedback" class="c-form--error">{{feedback}}</div>
    </login>
  </div>
</template>

<script>
  import auth from "client/auth";

  export default {
    name: "login-page",
    data: function() {
      return {
        feedback: null,
      }
    },
    components: {
      login: require('./login.vue').default,
    },
    methods: {
      login: function (event) {
        this.feedback = null;
        auth.login(event.credentials, '/admin').then(() => {
          this.feedback = null;
        }, err => {
          console.log(err);
          this.feedback = this.$t('authentication.error');
        })
      },
    },
  };
</script>

<style scoped>
</style>
