import store from 'client/store';

export default {
  path: 'display',
  component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/wrapper.vue'),
  beforeEnter: function (to, from, next) {
    Promise.all([
      store.dispatch("displays.load", {
        eventId: to.params.eventId
      }),
      store.dispatch("displayTemplates.load", {
        userId: store.state.user.instance.id,
      }),
    ]).then(function () {
      next();
    });
    store.dispatch("displays.load", {
      eventId: to.params.eventId
    }).then(() => {
      next();
    });
  },
  children: [{
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/displayWrapper.vue'),
    children: [{
      path: '/',
      name: 'admin.event.display.list',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/list.vue'),
    },{
      path: 'add',
      name: 'admin.event.display.add',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/add.vue'),
    }, {
      path: 'edit/:displayId',
      name: 'admin.event.display.view',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/view.vue'),
    }]
  },{
    path: 'images',
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/imageWrapper.vue'),
    beforeEnter: function (to, from, next) {
      store.dispatch("displayImages.load", {
        eventId: to.params.eventId
      }).then(() => {
        next();
      });
    },
    children: [{
      path: '/',
      name: 'admin.event.display.images.list',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/imageList.vue'),
    }, {
      path: 'add',
      name: 'admin.event.display.images.add',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/imageAdd.vue'),
    }, {
      path: ':imageId',
      name: 'admin.event.display.images.view',
      component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/imageView.vue'),
    }],
  }],
};
