import Vue from 'vue';
import store from 'client/store';
import config from 'client/config';
import socket from 'client/socket';
import router from "@/app/router";

// Send a request to the login URL and save the returned JWT
function login(creds, dest) {
  return new Promise((resolve, reject) => {
    Vue.http.post(config.root + '/seUsers/login', creds).then(result => {
      localStorage.setItem('id_token', result.body.id);
      localStorage.setItem('id_user', result.body.userId);
      this.check().then(result => {
        router.push({
          path: dest,
        });
        resolve(result);
      }, err => reject(err));
    }, err => reject(err));
  });
}

function create(creds) {
  localStorage.setItem('id_token', creds.token);
  localStorage.setItem('id_user', creds.userId);
  return check();
}

// To log out, we just need to remove the token
function logout() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('id_user');
  store.dispatch("user.logout");
  socket.disconnect();
}

function check() {
  return new Promise(function (resolve, reject) {
    const token = localStorage.getItem('id_token');
    const userId = localStorage.getItem('id_user');
    if (token !== null) {
      Vue.http.headers.common['Authorization'] = token;
      socket.emit('authentication', {token: token, userId: userId});
      socket.emit('setUserId', userId);
      store.dispatch('user.set', {id: userId, token: token}).then(() => {
        resolve();
      }).catch(function (err) {
        reject(err);
      })
    } else {
      reject("no user in local store");
    }
  });
}

export default {
  login: login,
  create: create,
  logout: logout,
  check: check,
};
