import store from 'client/store';
import auth from "client/auth";
import lang from 'client/lang';
import userLib from 'client/lib/user';

import Vue from 'vue';

export default [
  {
    path: '/admin',
    beforeEnter: function (to, from, next) {
      auth.check().then(function () {
        // authenticated user, now check for customer or admin role
        if (! userLib.checkRoles(['customer', 'admin'])) {
          next(from.path);
          return;
        }

        let tasks = [store.dispatch('disciplines.get')];
        if (userLib.checkRoles(['admin'])) {
          tasks.push(store.dispatch('events.getAll'));
          tasks.push(store.dispatch('users.get'));
        } else {
          tasks.push(store.dispatch('events.get', store.state.user.instance.id));
        }

        Promise.all(tasks).then(next);
      }).catch(function (err) {
        console.log(err);
        next({
          path: '/login',
        });
      });
    },
    component: () => import(/* webpackChunkName: "admin" */ 'client/admin/home/admin.vue'),
    children: [
      {
        path: '/',
        components: {
          default: () => import(/* webpackChunkName: "admin" */ 'client/admin/home/wrapper.vue'),
          menu: () => import(/* webpackChunkName: "admin" */ 'client/admin/home/menu.vue'),
        },
        beforeEnter: function (to, from, next) {
          if (store.state.user.instance.locale) {
            Vue.i18n.locale = store.state.user.instance.locale;
          }
          next();
        },
        children: [
          {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "admin" */ 'client/admin/home/events.vue'),
          }, {
            path: 'eventAdd',
            name: 'admin.eventAdd',
            component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/add.vue'),
          },
          require('client/admin/user/routingCustomers.js').default,
          require('client/admin/user/routingUser.js').default,
          require('client/admin/sync/routing.js').default,
          {
            path: 'countries',
            name: 'admin.countries',
            component: () => import(/* webpackChunkName: "admin" */ 'client/admin/home/countries.vue'),
          }
        ],
      },
      {
        path: 'event/:eventId',
        beforeEnter: function (to, from, next) {
          const eventId = to.params.eventId

          const eFilter = {eventId}
          Promise.all([
            store.dispatch('events.getById', eFilter),
            store.dispatch('eventDisciplines.get', eFilter),
            store.dispatch('clubs.get', eFilter),
            store.dispatch('members.get', eFilter),
            store.dispatch('groups.get', eFilter),
            store.dispatch('teams.get', eFilter)
          ]).then(() => {
            const event = store.state.events.current

            if (event.language) {
              lang.setLocale(event.language)
            }

            next()
          });
        },
        components: {
          default: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/wrapper.vue'),
          menu: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/menu.vue'),
        },
        children: [{
          path: '/',
          name: 'admin.event.view',
          component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/view.vue'),
        },
          require('client/admin/event/clubs/routing.js').default,
          require('client/admin/event/display/routing.js').default,
          require('client/admin/event/qr/routing.js').default,
          {
            path: 'discipline/add',
            name: 'admin.event.discipline.add',
            component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/event-discipline/add.vue'),
          }, {
            path: 'discdummy/:disciplineId',
            name: 'admin.event.discipline.dummy',
            component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/event-discipline/dummy.vue'),
          }, {
            path: 'discipline/:disciplineId',
            component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/event-discipline/wrapper.vue'),
            beforeEnter: function (to, from, next) {
              const dFilter = {eventDisciplineId: to.params.disciplineId};
              Promise.all([
                store.dispatch("exercises.get", dFilter),
                store.dispatch("eventDiscipline.get", dFilter),
                store.dispatch("categories.get", dFilter),
                store.dispatch("sessions.getByDiscipline", dFilter),
                store.dispatch("participations.get", dFilter),
                store.dispatch("blocks.get", dFilter),
                store.dispatch("blockParticipations.get", dFilter),
                store.dispatch("rankings.get", dFilter),
              ]).then(() => {
                next();
              }).catch(reason => {
                console.log(reason);
              });
            },
            children: [{
              path: '/',
              name: 'admin.event.discipline',
              component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/event-discipline/dummy.vue'),
            },
              require('client/admin/event/dashboard/routing.js').default,
              {
                path: 'settings',
                name: 'admin.event.discipline.settings',
                component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/event-discipline/view.vue'),
              }, {
                path: 'rounds',
                name: 'admin.event.discipline.rounds',
                component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/event-discipline/rounds.vue'),
              },
              require('client/admin/event/categories/routing.js').default,
              require('client/admin/event/sessions/routing.js').default,
              require('client/admin/event/participants/routing.js').default,
              require('client/admin/event/results/routing.js').default,
              require('client/admin/event/scoreinput/routing.js').default,
              require('client/admin/event/import/routing.js').default,
              {
                path: 'displayControl',
                name: 'admin.event.discipline.displayControl',
                beforeEnter: function (to, from, next) {
                  store.dispatch("displays.load", {
                    eventId: to.params.eventId
                  }).then(() => {
                    next();
                  });
                },
                component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/display/control.vue'),
              },
              {
                path: 'export',
                name: 'admin.event.discipline.export',
                component: () => import(/* webpackChunkName: "admin" */ 'client/admin/event/export/export.vue'),
              },
            ]
          }]
      }]
  }
];
